<template>
  <v-container v-if="!loading" id="category-table" fluid tag="section">
    <base-material-card
      color="primary"
      icon="mdi-office-building"
      :title="partnerB2bInfo.name"
      class="px-5 py-3"
    >
      <ManageUserPartner
        @alert="showAlert"
        @saved="userSaved"
        :user_prop="partnerB2bInfo"
      ></ManageUserPartner>
      <v-alert
        class="ma-2"
        v-if="alertMsg != null"
        :type="success ? 'success' : 'error'"
      >
        {{ $t(alertMsg) }}
      </v-alert>
    </base-material-card>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "EditPartnerB2b",
  components: {
    ManageUserPartner: () =>
      import("@/views/pages/user/index/form/tabs/Partner.vue"),
  },
  data: () => ({
    partnerB2bInfo: null,
    apiService: new ApiService(),
    loading: false,
    b2bId: null,
    success: null,
    alertMsg: null,
    user: null,
  }),
  async created() {
    const partnerB2bId = this.$route.params.id;

    if (partnerB2bId) {
      this.b2bId = partnerB2bId;
      await this.getPartnerB2bInfo();
    }
  },
  methods: {
    async getPartnerB2bInfo() {
      this.loading = true;
      await this.apiService
        .getRequest(`partnerb2b/${this.b2bId}`)
        .then((resp) => {
          this.partnerB2bInfo = resp.content;
        })
        .catch((err) => {
          this.$toast.error("an_error_ocurred");
        });
      this.loading = false;
    },
    showAlert(success, msg) {
      this.success = success;
      this.alertMsg = msg;
    },
    userSaved(user) {
      this.user = user;
    },
  },
};
</script>

<style scoped></style>
